import React from "react";
import { useHistory } from "react-router-dom";
import Logo from "../components/logo";
import Link from "@material-ui/core/Link";
import AuthService from "../services/auth-service";
import { ReactComponent as ProfileShortcutIcon } from '../icons/Profile_shortcut.svg';
const UserLogo = ({ user, click }) => {
  console.log(user);

  if (user) {
    let name = user.username;
    let letters = "";
    if (name)
      name.split(" ").forEach((name) => {
        letters = letters + name.charAt(0);
      });
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5px",
          paddingLeft: "5%",
          cursor:"pointer"
        }}
        onClick={click}
      >
        <Link href="/profile">
          <img
            src={process.env.PUBLIC_URL + "/rata.svg"}
            style={{
              width: "35px",
            }}
          />
        </Link>

        <span
          style={{
            position: "absolute",
            fontFamily: "Canaro",
            fontSize: "13px",
            color: "#FA551E",
            fontWeight: "bolder",
            marginTop: "-3px",
          }}
        >
          {letters.toUpperCase()}
        </span>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5px",
          paddingLeft: "5%",
          cursor:"pointer"
        }}
        onClick={click}
      >
        <ProfileShortcutIcon style={{fill:"#FA551E"}} />
      </div>
    );
  }
};

export default function TopNav({ ...rest }) {
  const history = useHistory();

  const [user, setUser] = React.useState(undefined);

  React.useEffect(async () => {
    console.log(user);
    if (!user) {
      const _user = await AuthService.getCurrentUser();
      setUser(_user);
    }
  }, [user]);

  const click = () => {
    if(user){
      history.push("/profile");
    }else{
      history.push("/signin");
    }
   
  };

  return (
    <React.Fragment>
      <div
        style={{
          padding: "10px",
          //boxShadow: "rgb(149 157 165 / 20%) 0px 8px 24px",
          minHeight: "65px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #eee",
        }}
      >
        <Logo style={{ width: "100px" }} onClick={()=>{history.push("/")}}></Logo>
        <UserLogo click={click} user={user} />
      </div>
    </React.Fragment>
  );
}
