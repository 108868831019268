import React from "react";
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
export default function addIcon({ history,location,click, ...rest }) {
    const onClose = () => {
        if (history) {
          history.push(location);
        }
      };
  return (
    <AddIcon
      
      style={{ marginTop: "0px", transform: "scale(1.2)", cursor: "pointer",fill:"#FA551E" }}
      onClick={history ? onClose:click}
    ></AddIcon>
  );
}
