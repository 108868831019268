import React, { useEffect } from "react";
import GalpLayout from "./galpLayout";
import AppLayout from "./layout";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth-service";
import BuyByeService from "../services/buybye-service";
import moment from "moment";
import Loader from "./loader";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import { DialogContent } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  cardNumber: {
    marginBottom: "0px",
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-around",
  },
  marginBottom: {
    marginBottom: "12vh",
  },
  mt1: {
    margin: theme.spacing(0.5),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));
const Address = ({ data }) => (
  <div>
    <address className="header__address">
      <i className="fas fa-map-marker-alt" />
      {data.subtitle}
    </address>
  </div>
);
const HeaderTitle = ({ data }) => (
  <h1 className="header__title">{data.title}</h1>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
  </div>
);
const HeaderItem = ({ data }) => {
  return (
    <div className="header__item">
      <HeaderTop data={data} />
      <Address data={data} />
    </div>
  );
};
const RecieptsCard = ({ classes, data, id }) => {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const onChange = (prop) => (event) => {
    console.log(prop);
    history.push("/receipts/" + prop);
    //setValues({ ...values, [prop]: event.target.value });
  };
  return (
    <div className="receiptsBox" onClick={onChange(id)}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="receiptHour" style={{ margin: "4%" }}>
          {moment(data.endDate).format("DD MMM YYYY")} |{" "}
          {moment(data.endDate).format("HH:mm")}
          <div className="receiptID">{data.sessionUUID}</div>
        </div>
        <div style={{ margin: "4%", color: "#5A6473" }}>
          <ChevronRightIcon />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="receiptsItems" style={{ margin: "4%" }}>
          <img
            src={process.env.PUBLIC_URL + "/cart.svg"}
            style={{ transform: "scale(1.5)", marginRight: "8px" }}
          />
          {data.totalProduct} Items
        </div>{" "}
        <div className="receiptsTotal" style={{ margin: "4%" }}>
          {data.totalValue}
          {data.currency}
        </div>
      </div>
    </div>
  );
};
const Reciepts = (props) => {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [cards, setCards] = React.useState(undefined);
  //const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      console.log(user);
    } else {
      history.push("/signin");
      return;
    }
    async function main() {
      let res = await BuyByeService.getUserShops();
      if (res.success) {
        console.log(res.result);

        function dynamicSort(property) {
          var sortOrder = 1;
          if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
          }
          return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            var result =
              a[property] < b[property]
                ? -1
                : a[property] > b[property]
                ? 1
                : 0;
            return result * sortOrder;
          };
        }

        let sorted = res.result.sort(dynamicSort("-endDate"));

        setCards(sorted);
      }
    }
    main();
  }, [history]);

  const classes = useStyles();
  if (!cards) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  return (
    <GalpLayout>
      <div className={clsx(classes.marginBottom)} style={{ padding: "0% 3%" }}>
        <div>
          <div className="receiptsTitle" style={{ marginTop: "20px" }}>
            {Languages[locale].purchaseList}
          </div>
        </div>
        <div className={(classes.mt1, classes.marginTop)}>
          {cards.map((card) => (
            <RecieptsCard
              key={card._id + Math.floor(Math.random() * (125 - 5) + 5)}
              id={card._id}
              classes={classes}
              data={card}
            />
          ))}
        </div>
      </div>
    </GalpLayout>
  );
};
export default Reciepts;
