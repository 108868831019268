import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import TopNav from "../components/topNav";
import Link from "@material-ui/core/Link";

export default function SignIn() {
  const history = useHistory();

  return (
    <AppLayout title={""} style={{ padding: 0 }}>
      <TopNav></TopNav>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
         
        }}
      >
        <Link href="/profile">
          <img
            src={process.env.PUBLIC_URL + "/full.svg"}
            style={{
             
            }}
          />
        </Link>

        <span
          style={{
            position: "absolute",
            fontFamily: "Canaro",
            fontSize: "20px",
            color: "white",
            fontWeight: "bolder",
          }}
        >
          PR
        </span>
      </div>
      <div style={{display: "flex",
          justifyContent: "center",
          alignItems: "center",}}><h1 style={{fontFamily: "Canaro",
          fontSize: "20px",color:"#353C47"}}>Example</h1></div>
      <img
        src={process.env.PUBLIC_URL + "/full.svg"}
        style={{
          width: "35px",
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </AppLayout>
  );
}
