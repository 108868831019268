export default {
  //BOTTOM NAV
  home: "Início",
  buybye: "BuyBye",
  account: "Conta",
  purchaseList: "Lista de Compras",
  //HOME
  openBuyBye: "Abrir o BuyBye",
  // CABINET
  openBuyByeTitle: "Abrir a porta",
  openBuyByeSubtitle: "Introduza o código localizado no canto superior direito da porta para começar a comprar.",
  // receipts
  purchaseLabel: "Compra",
  receiptSubtitle: (value) => {
    return `Compra ${value} `;
  },
  receiptDuration: (value) => {
    return `A sua compra demorou <strong>${value}s.</strong>`;
  },
  receipt:{
    download:"Descarregar fatura",
    help:"Precisa de ajuda?",
    talk:"Fale connosco"
  },
  // close door page
  closeDoor: {
    p1:"Pagamento em curso",
    p2:"Por favor, aguarde."
  },
  //Cases List
  casesListLabel: "Fale connosco",
  casesListItensCase: "Pedido",
  casesListItensCreatedAt: "Criado em",
  casesListItensMachine: "Máquina",
  casesListItensStatus: "Estado",
  casesListNoItens: "Não tem pedidos registados.",
  casesListItensStatusTrad: {
    Open: "Aberto",
    Closed: "Fechado",
    "In Resolution": "Em resolução",
  },
  // verify
  verify: { title: "Código de Validação" },
  // help form
  helpForm: {
    title: "Feedback Cliente",
    mentionId: "Código Máquina ou ID da compra",
    subject: "Assunto",
    description: { label: "Mensagem", placeholder: "Escreva a sua mensagem" },
    contactMethod: {
      lable: "Método de Contacto",
      phone: "Telefone",
      email: "E-mail",
    },
    email: "E-mail",
    phone: "Telefone",
  },
  //caseManagement
  caseManagement: {
    title: "Fale connosco",
    subtitle: {
      title: "Fale Connosco.",
      subtitle:
        "Precisa de assistência? Envie-nos uma mensagem com as suas questões.",
    },
  },
  // actions
  actions: {
    sendMessage: "Enviar mensagem",
    setNewPassword: "Mudar palavra-passe",
    getCode: "Pedir código de verificação",
  },

  //forgot Password
  forgot: {
    phoneNumber: "Indique o seu contacto telefónico",
    helpTip: "",
  },
  // error
  error: {
    requiredFields: "Por favor preencha todos os campos.",
    validData: "Por favor preencha os campos com informação fidedigna.",
  },
  // global
  global: {
    download:"Descarregar fatura",
    email: "E-mail",
    name: "Nome",
    phone: "Telemovel",
    username: "Nome",
    acceptTerms: "* Clicando estará a aceitar os",
    signInTip: "Já tem conta? Faça login",
    nif: "NIF",
    logout: "Logout",
    pt: " Português",
    en: " English",
    language: "Linguagem",
    password: "Palavra-passe",
    newPassword: "Nova palavra-passe",
    signIn: "Entrar",
    signup:"Registar",
    forgotPassword: "Esqueceu-se da palavra-passe?",
    signUp: "Ainda não tem conta? Registe-se",
    agree:"Ao clicar em registar está a aceitar os",
    terms: "Termos e Condições",
    policy:"Política de Privacidade"
  },
  //payments
  payments: {
    title: "Cartões de Pagamento",
    noCards: "Registe o seu primeiro cartão.",
    expirationDate: "Data de expiração",
    modalTitle: "Adicionar novo cartão",
    epg: {
      body: `To guarantee the veracity of the card we will capture 1 AED on card
      registration. Don't worry, this amount will be returned after the
      operation. 
      <br></br>All card details are protected by 3D secure transaction
      provided by Etisalat&reg;.`,
    },
    stripe: {
      body: `O nosso sistema de pagamentos pode enviar um pedido de autorização de 1€ ao banco emissor para confirmar se o cartão é válido e se o banco permite a autorização.
      <br></br>
      Esta é uma cobrança de autorização temporária e desaparecerá do seu extrato.`,
    },
  },
  //inShop
  inShop: {
    title: "Compra",
    text: {
      p1: "Retire os produtos que pretende.",
      p2: "Feche a porta para concluir a sua compra.",
      p3: "Por favor mantenha os produtos que não pretende comprar no lugar original.",
    },
  },
  // profile form
  profile: {
    title: "Perfil",
  },

  // debt form
  debt: {
    title: "Ups. Parece que tens um débito pendente.",
    body: (values) => {
      if (!values) {
        values = { total: "", currency: "" };
      }
      return `Para continuares a comprar no Galp Grab & Go tens de pagar o valor em falta ${values.total} ${values.currency} (referente a ${values.debts} recibos). Ao carregares em continuar estás a autorizar o pagamento em falta.`;
    },
    cancel: "Cancelar",
    continue: "Continuar",
  },
};
