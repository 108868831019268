import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "../components/button";

const useStyles = makeStyles((theme) => ({
  header: {
    position: "relative",
    overflow: "hidden",
    margin: "20px 0",
    willChange: "transform, opacity",
  },
  title: {
    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    /* identical to box height */

    color: "#616771",
  },
  subtitle:{
    /* Whatever you need, let us know and we’ll do our best to help with your enquiry. */

    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#A8AEB7",
  }
  
}));

const Address = ({ data,classes }) => (
  <address className={classes.subtitle}>{data.subtitle}</address>
);
const HeaderTitle = ({ data,classes }) => (
  <h1 className={classes.title}>{data.title}</h1>
);

const HeaderTop = ({ data,classes }) => (
  <div className="header__top">
    <Button
      className="nav-bar__back"
      onClick={() => {
        data.click();
      }}
    >
      {" "}
      {data.click ? (
        <img src={process.env.PUBLIC_URL + "/icons/Back.svg"} style={{ fontSize: "1.3em", marginRight: "5px" }} />
      ) : null}
      <HeaderTitle data={data}
      classes={classes} />
    </Button>
  </div>
);
const HeaderItem = ({ data }) => {
  const classes = useStyles();

  return (
    <div className="header__item">
      <HeaderTop data={data} classes={classes} />
      <Address data={data} classes={classes}/>
    </div>
  );
};
const Header = ({ ...props }) => {
  const classes = useStyles();
  let container;
  if(props.container == false){
    container = ""
  }
  else{
    container="container"
  }
  return (
    <div className={[clsx(classes.header), container].join(" ")}>
      {props.children}
    </div>
  );
};

export default {
  HeaderItem,
  HeaderTop,
  HeaderTitle,
  Address,
  Header,
};
