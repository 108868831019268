import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import { ReactComponent as OpenIcon } from "../icons/Open_door.svg";
import { ReactComponent as PurchasesIcon } from "../icons/Purchases.svg";
import { ReactComponent as ProductsIcon } from "../icons/Products.svg";

export default function GalpLayout({ children, bottomNav = true }) {
  const history = useHistory();
  let _id;
  try {
    _id = history.location.pathname.replaceAll("/", "");
  } catch {
    _id = "";
  }
  let params = { id: _id };
  console.log(history.location.pathname);
  const userId = params.id ? params.id : "home";
  return (
    <AppLayout topNavigation={true} style={{ padding: 0 }}>
      <div
        className="setMargin"
        style={{ padding: "0 3%", maxHeight: "100vh", overflowY: "scroll" }}
      >
        {children}
        {bottomNav ? (
          <div className="bottomNavGalp">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <ProductsIcon
                style={{
                  fill: userId =="home" ? "#FA551E": "#616771",
                }}
                onClick={() => {
                  history.push("/");
                }}
              />
              <span
                style={{
                  color: userId =="home" ? "#FA551E": "#616771",
                }}
              >
                Products
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop:"-35px"
              }}
            >
              <OpenIcon
                style={{
                  fill: "#FA551E",
                }}
                onClick={() => {
                  history.push("/cabinet");
                }}
              />
              <span
                style={{
                  color: "#FA551E",
                }}
              >
                Open Door
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <PurchasesIcon
                style={{
                  fill: userId =="receipts" ? "#FA551E": "#616771"
                }}
                onClick={() => {
                  history.push("/receipts");
                }}
              />
              <span
                style={{
                  color: userId =="receipts" ? "#FA551E": "#616771",
                }}
              >
                Purchases
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </AppLayout>
  );
}
