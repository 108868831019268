import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import BuyByeService from "../services/buybye-service";
import Loader from "../pages/loader";
import moment from "moment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import AddIcon2  from './icons/addIcon';
const useStyles = makeStyles((theme) => ({
  cardNumber: {
    marginBottom: "0px",
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "1em",
    paddingTop: "1em",
    justifyContent: "space-around",
    cursor: "pointer",
  },
  icon: {
    display: "inline-block",
    marginRight: "1em",
    width: "45px",
    height: "45px",
    marginBlockStart: "1em",
  },
  trashIcon: {
    marginBlockStart: "1em",
    color: theme.palette.red,
    fontSize: "2em",
  },
  creditcards: {
    paddingBottom: "11%",
  },
  paymentHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  floatContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20%",
    marginTop: "1em",
  },
  center: {
    alignSelf: "center",
  },
  title: {
    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    /* identical to box height */
    color: "#616771",
    margin: "20px 0px",
  },
  cardTitle: {
    /* Visa/Electron ****4242 */

    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "17px",
    /* identical to box height */

    color: "#616771",
    textTransform: "capitalize",
  },
  cardSubtitle: {
    /* Visa/Electron ****4242 */

    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    /* identical to box height */

    color: "#A8AEB7",
    textTransform: "capitalize",
  },
  cardText: {
    /* Visa/Electron ****4242 */

    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    /* identical to box height */

    color: "#616771",
  },

  "margin-20": {
    margin: "20px",
  },
  "m-t-1":{
    marginTop:"10px",
  },
  "m-t-2":{
    marginTop:"20px",
  },
  "m-t-3":{
    marginTop:"30px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardIcons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "padding-20-20": {
    padding: "20px 20px",
  },
}));
const NoCards = ({ ...props }) => {
  return <Typography>{Languages[props.locale].casesListNoItens}</Typography>;
};
const RecieptsCard = ({ ...props }) => {
  const classes = useStyles();
  console.log(props.locale);
  return (
    <div
      className={clsx(classes["flex"], "receiptsBox", classes["padding-20-20"])}
      key={props.data._id}
      onClick={props.caseClick(props.data._id)}
    >
      <div className={clsx(classes.flexRow)}>
        <div>
          <Typography className={clsx(classes['m-t-1'],classes.cardTitle)} color="primary">
            {Languages[props.locale].casesListItensCase} : {props.data.serial}
          </Typography>
          <Typography className={clsx(classes['m-t-1'],classes.cardText)} color="primary">
            {Languages[props.locale].casesListItensMachine} :{" "}
            {props.data.machine.code}
          </Typography>

          <Typography
            className={[clsx(classes['m-t-1'],classes.cardText), clsx(classes.center)].join(" ")}
            color="primary"
          >
            {props.data.subject}
          </Typography>
        </div>

        <div>
          <ChevronRightIcon style={{
            fontSize:"40px",
            color:"#616771"
            }}/>
        </div>
      </div>
      <div className={clsx(classes.flexRow, classes["m-t-1"])}>
        <Typography className={clsx(classes.mt1,classes.cardText)} color="primary">
          {Languages[props.locale].casesListItensStatus} :{" "}
          <span style={{color:"#FA551E"}}>{Languages[props.locale].casesListItensStatusTrad[props.data.status]}</span>
        </Typography>
        <Typography
          className={[clsx(classes.mt1), clsx(classes.center, classes.cardSubtitle)].join(" ")}
        >
          {moment(props.data.createdAt).format("DD/MM/YYYY")}
        </Typography>
      </div>
    </div>
  );
};
export default function CaseList() {
  const history = useHistory();
  const classes = useStyles();
  const [cases, setCases] = React.useState(null);
  const { locale } = React.useContext(LocaleContext);

  var backButtonPrevented = true;

  function popStateListener(event) {
    if (backButtonPrevented === false) {
      // Nope, go back to your page
      history.go(1);
      //backButtonPrevented = true;
    } else {
      window.removeEventListener("popstate", popStateListener);
    }
  }

  window.addEventListener("popstate", popStateListener);
  React.useEffect(() => {
    async function main() {
      let res = await BuyByeService.getUserCases();
      if (res.success) {
        console.log(res);
        setCases(res.result);
      }
    }
    main();
  }, [history]);

  const onClick = (e) => {
    console.log(e);
    history.push("/case-management");
    return;
  };

  const caseClick = (id) => (e) => {
    history.push("/case-management/" + id);
    return;
  };

  if (!cases) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  return (
    <div className={clsx()}>
      <div className={clsx(classes.paymentHeader)}>
        <h1 className={clsx(classes.title)}>
          {Languages[locale].casesListLabel}
        </h1>
        <AddIcon2 history={history} location={"/case-management"}></AddIcon2>
      </div>
      <div>
        {cases.length == 0 && <NoCards locale={locale} />}

        {cases.map((c) => (
          <RecieptsCard
            key={Math.floor(Math.random() * (125 - 5) + 5)}
            id={Math.floor(Math.random() * (125 - 5) + 5)}
            data={c}
            caseClick={caseClick}
            locale={locale}
          />
        ))}
      </div>
    </div>
  );
}
