import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function Copyright() {
  const { locale } = React.useContext(LocaleContext);
  return (
    <React.Fragment>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ fontWeight: "bolder" }}
      >
        <Link href="#">{Languages[locale].global.terms}</Link> <span>{"&"}</span>{" "}
        <Link href="#">{Languages[locale].global.policy}</Link>
      </Typography>
     
    </React.Fragment>
  );
}
