import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import visa from "payment-icons/min/single/visa.svg";
import maestro from "payment-icons/min/single/maestro.svg";
import mastercard from "payment-icons/min/single/mastercard.svg";
import Fab from "@material-ui/core/Fab";
import AddIcon2  from './icons/addIcon';
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import BuyByeService from "../services/buybye-service";
import Loader from "../pages/loader";
import Checkbox from "@material-ui/core/Checkbox";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Dial = ({ ...props }) => {
  console.log(props);
  if (props.paymentSystem === "epg") {
    return (
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {Languages[props.locale].payments.modalTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            dangerouslySetInnerHTML={{
              __html:
                Languages[props.locale].payments[props.paymentSystem].body,
            }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            {Languages[props.locale].debt.cancel}
          </Button>
          <form action={props.PaymentPage} method="post">
            <input
              type="Hidden"
              name="TransactionID"
              readOnly={true}
              value={props.TransactionID || ""}
            />
            <Button type="submit" value="Submit">
              {Languages[props.locale].debt.continue}
            </Button>
          </form>
        </DialogActions>
      </Dialog>
    );
  } else if (props.paymentSystem === "stripe") {
    return (
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {Languages[props.locale].payments.modalTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            dangerouslySetInnerHTML={{
              __html:
                Languages[props.locale].payments[props.paymentSystem].body,
            }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            {Languages[props.locale].debt.cancel}
          </Button>
          <Button onClick={props.goRegister} value="Submit">
            {Languages[props.locale].debt.continue}
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <></>;
  }
};

const useStyles = makeStyles((theme) => ({
  cardNumber: {
    marginBottom: "0px",
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-around",
  },
  icon: {
    display: "inline-block",
    marginRight: "1em",
    width: "45px",
    height: "45px",
    marginBlockStart: "1em",
  },
  trashIcon: {
    marginBlockStart: "1em",
    color: theme.palette.red,
    fontSize: "2em",
    cursor: "pointer",
  },
  creditcards: {
    paddingBottom: "11%",
  },
  paymentHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  floatContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20%",
  },
  title: {
    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    /* identical to box height */
    color: "#616771",
    margin: "20px 0px",
  },
  cardTitle: {
    /* Visa/Electron ****4242 */

    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "17px",
    /* identical to box height */

    color: "#616771",
    textTransform: "capitalize",
  },
  cardSubtitle: {
    /* Visa/Electron ****4242 */

    fontFamily: "Canaro",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    /* identical to box height */

    color: "#A8AEB7",
    textTransform: "capitalize",
  },
  "margin-20": {
    margin: "20px",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardIcons:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "padding-20-10":{
    padding:"20px 10px",
  }
}));

const PaymentCards = ({ classes, data, ...props }) => {
  const history = useHistory();
  const onClick = (card) => async (e) => {
    console.log(card);

    let res = await BuyByeService.deleteCustomerCard(card._original);
    if (res.success) {
      history.go(0);
    } else {
      console.log(res);
    }
  };
  const setDefault = (card) => async (e) => {
    console.log(card);

    let res = await BuyByeService.setCustomerDefaultCard(card._original);
    if (res.success) {
      history.go(0);
    } else {
      console.log(res);
    }
  };
  return (
    <div className={clsx(classes["flex"], "receiptsBox", classes["padding-20-10"])}>
      <div className={clsx(classes["margin-20"])}>
        <h1 className={clsx(classes.cardTitle)}>
          {data.cardType} {data.cardNumber}
        </h1>
        <h2 className={clsx(classes.cardSubtitle)}>
          {" "}
          {Languages[props.locale].payments.expirationDate}: {data.cardDate}
        </h2>
      </div>
      <div className={clsx(classes.cardIcons)}>
        <Checkbox
          checked={data.default_card ? data.default_card : false}
          onChange={setDefault(data)}
          color="primary"
        />
        <img
          src={process.env.PUBLIC_URL + "/thrash.svg"}
          style={{
            width: "20px",
          }}
          onClick={onClick(data)}
        />
      </div>
    </div>
  );
};

const NoCards = ({ ...props }) => {
  return <Typography> {Languages[props.locale].payments.noCards}</Typography>;
};
const cardLogo = (brand) => {
  if (brand.toLowerCase() === "visa") return visa;
  else if (brand.toLowerCase() === "maestro") return maestro;
  else return mastercard;
};
export default function Payments() {
  const history = useHistory();
  const classes = useStyles();
  const [cards, setCards] = React.useState(undefined);
  const { locale } = React.useContext(LocaleContext);
  const [open, setOpen] = React.useState(false);
  const [PaymentPage, setPaymentPage] = React.useState(undefined);
  const [TransactionID, setTransactionID] = React.useState(undefined);
  const [PaymentSystem] = React.useState(BuyByeService.getPaymentSystem());

  const handleClickOpen = () => {
    console.time();
    async function main() {
      if (PaymentSystem === "epg") {
        let _cards;
        console.log("payments");
        _cards = await BuyByeService.createCustomerCard({
          step: 1,
          ReturnPath: `https://utils.reckon.ai/bypass?url=${
            process.env.BYPASS || `${process.env.PUBLIC_URL}/closeDoor?step=2`
          }`,
        });
        console.log(_cards);
        if (_cards.success) {
          BuyByeService.setToken("lastTransactionID", {
            TransactionID: `${_cards.result.TransactionID}`,
          });
          setPaymentPage(_cards.result.PaymentPage);
          setTransactionID(`${_cards.result.TransactionID}`);
          setOpen(true);
        }
        console.timeEnd();
      } else if (PaymentSystem === "stripe") {
        setOpen(true);
      } else {
        setOpen(true);
      }
    }
    main();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const goRegister = () => {
    history.push("/card/form");
  };

  useEffect(() => {
    async function main() {
      let _cards;
      _cards = await BuyByeService.getCustomerCards();
      console.log(_cards);
      if (_cards.success) {
        _cards = _cards.result.map((card) => {
          return {
            cardType: card.brand.toLowerCase(),
            cardNumber: `**** ${card.number}`,
            cardDate: `${card.exp_month}/${card.exp_year}`,
            source: cardLogo(card.brand),
            default_card: card.default_card,
            _original: card,
          };
        });
        setCards(_cards);
      }
    }
    main();
  }, []);
  const onClick = () => {
    //history.push("/card/form");
    handleClickOpen();
  };
  if (!cards) {
    return (
      <div style={{ height: "50vh" }}>
        <Loader></Loader>
      </div>
    );
  }
  return (
    <div className={clsx()}>
      <div className={clsx(classes.paymentHeader)}>
        <h1 className={clsx(classes.title)}>
          {Languages[locale].payments.title}
        </h1>
        <AddIcon2 click={onClick}></AddIcon2>
      </div>

      <div className={classes.creditcards}>
        {cards &&
          cards.map((card) => (
            <PaymentCards
              key={`${card.cardNumber}`}
              classes={classes}
              data={card}
              locale={locale}
            />
          ))}
        {!cards || (cards.length <= 0 && <NoCards locale={locale}></NoCards>)}
      </div>
      <div className={classes.floatContainer}>
        
        <Dial
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          handleClose={handleClose}
          goRegister={goRegister}
          PaymentPage={PaymentPage}
          TransactionID={TransactionID}
          paymentSystem={PaymentSystem}
          locale={locale}
        ></Dial>
      </div>

    </div>
  );
}
