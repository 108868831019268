import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import Container from "../components/container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import queryString from "query-string";
const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    marginTop: "2.5em",
  },
}));
export default function SignIn() {
  const history = useHistory();
  const classes = useStyles();
  const { locale } = React.useContext(LocaleContext);
  const parsed = queryString.parse(history.location.search);
  const [basket, setBasket] = React.useState(
    parsed && String(parsed.interaction) == "1"
      ? process.env.PUBLIC_URL + "/1.jpeg"
      : process.env.PUBLIC_URL + "/1.jpeg"
  );

  history.listen((location) => {
    const parsed = queryString.parse(history.location.search);
    setBasket(
      parsed && String(parsed.interaction) == "1"
        ? process.env.PUBLIC_URL + "/1.jpeg"
        : process.env.PUBLIC_URL + "/1.jpeg"
    );
  });
  console.log(basket);

  var backButtonPrevented = false;

  function popStateListener(event) {
    if (backButtonPrevented === false) {
      // Nope, go back to your page
      history.go(1);
      //backButtonPrevented = true;
    } else {
      window.removeEventListener("popstate", popStateListener);
    }
  }

  window.addEventListener("popstate", popStateListener);
  return (
    <AppLayout center style={{ padding: 0 }}>
      <CabinetCode
        place={Languages[locale].inShop.text.p1}
        text1={Languages[locale].inShop.text.p2 + Languages[locale].inShop.text.p3}
        classes={classes}
      />
    </AppLayout>
  );
}

function CabinetCode(props) {
  const { place, text1 } = props;
  return (
    <div className="container-center-horizontal">
      <div className="cabinet-code screen">
        <div className="overlap-group5" style={{ marginTop: "-115px" }}>
          <div className="overlap-group4" style={{ top: 120 }}>
            <div className="place buybye-flow-header" data-id="5:5">
              {place}
            </div>
            <p className="text-1 buybye-flow-text" data-id="5:6">
              {text1}
            </p>
          </div>
          <img
            style={{ transform: "scale(1)" }}
            className="frame"
            src={process.env.PUBLIC_URL + "/frame2.svg"}
          />
        </div>
      </div>
    </div>
  );
}
