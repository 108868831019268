import React from "react";
import GalpLayout from "./galpLayout";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HistoryOutlined } from "@material-ui/icons";

const SetAccordion = ({ title, open, children }) => {
  const [expanded, setExpanded] = React.useState(title);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Accordion
      style={{
        background: "transparent",
        borderRadius: "0px",
        boxShadow: "none",
      }}
      expanded={expanded === title}
      onChange={handleChange(title)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ borderBottom: "2px solid #FA551E" }}
      >
        <Typography className="accordionTitle">
          {title ? title : "Ac1"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
const SetGrid = ({ children }) => {
  return (
    <Grid
      container
      spacing={4}
      style={{ marginTop: "20px", justifyContent: "space-evenly" }}
    >
      {children}
    </Grid>
  );
};

const GridElement = ({ title, weight, img, price,...rest }) => {
  return (
    <Grid
      item
      xs={5}
      style={{
        maxWidth: "47.5%",
        flexBasis: "47.5%",
      }}
     { ...rest}
    >
      <div className="new">
        <h1 className="product-title"> {title || "Wrap de Atum"}</h1>
        <span className="product-weight">{weight || "300gr"}</span>
        <img src={img || process.env.PUBLIC_URL + "/wrap.png"} alt="wrap" style={{maxWidth:"100%"}}/>
        <span className="product-price">{price || "3.50€"}</span>
      </div>
    </Grid>
  );
};

export default function SignIn() {
  const history = useHistory();
  console.log("aqui")
  const onChange = (prop) => (event) => {
    console.log(prop);
    history.push("/product/" + prop);
    //setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <GalpLayout >
     
        <SetAccordion open={true} title={"Wraps"}>
          <SetGrid
            container
            spacing={4}
            style={{ marginTop: "20px", justifyContent: "space-evenly" }}
          >
            <GridElement onClick={onChange("123")}></GridElement>
            <GridElement onClick={onChange("123")}></GridElement>
          </SetGrid>
        </SetAccordion>
        <SetAccordion open={true} title={"Salads"}>
          <SetGrid
            container
            spacing={4}
            style={{ marginTop: "20px", justifyContent: "space-evenly" }}
          >
            <GridElement></GridElement>
            <GridElement></GridElement>
          </SetGrid>
        </SetAccordion>
        <SetAccordion open={true} title={"Poke Bowls"}>
          <SetGrid
            container
            spacing={4}
            style={{ marginTop: "20px", justifyContent: "space-evenly" }}
          >
            <GridElement></GridElement>
            <GridElement></GridElement>
          </SetGrid>
        </SetAccordion>
    </GalpLayout>
  );
}
