import React from "react";
import Button from "./button";
import Container from "./container";
import { useHistory } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  color: {
    background: `${theme.palette.primary.main} !important`,
    color: "white",
  },
}));
export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  console.log(locale);
  const onClick = () => {
    history.push("/cabinet");
  };
  return (
    <Container className="footer">
      <Button
        className={[clsx(classes.color), "event-button"].join(" ")}
        onClick={onClick}
      >
        <i className="fas fa-plus" />
        {Languages[locale].openBuyBye}
      </Button>
    </Container>
  );
}
