import React from "react";
import GalpLayout from "./galpLayout";
import { useHistory } from "react-router-dom";
import header from "../components/header";
import { ReactComponent as WeighIcon } from '../icons/Weigh.svg';
import { ReactComponent as HeartIcon } from '../icons/Heart.svg';

export default function SignIn() {
  const history = useHistory();
  const onClose = () => {
    history.goBack();
    return;
  };
  return (
    <GalpLayout>
      <header.Header>
        <header.HeaderItem
          data={{
            click: () => {
              onClose();
            },
          }}
        ></header.HeaderItem>
      </header.Header>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src={process.env.PUBLIC_URL + "/wrap.png"} />
        <span className="product-price">{"3.50€"}</span>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            marginRight: "10px",
          }}
        >
          <WeighIcon
            
            style={{
              fill:"#616771",
              marginRight: "2px",
            }}
          />
          <span>300gr</span>
        </div>
        <div>
          <HeartIcon
            
            style={{
              fill:"#FA551E",
              marginRight: "2px",
            }}
          />
          <span> 123 Likes</span>
        </div>
      </div>
      <div
        style={{
          margin: "35px 0px",
          fontFamily: "Canaro",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "17px",

          color: "#616771",
        }}
      >
        <h1>Product Details</h1>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          margin: "20px 0px",
        }}
      >
        <div
          style={{
            /* Group */

            filter: " drop-shadow(0px 2px 4px #D1D3D6)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            border: "1px solid #eee",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#616771",
            }}
          >
            Detail 1
          </span>
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#FA551E",
            }}
          >
            value
          </span>
        </div>
        <div
          style={{
            /* Group */

            filter: " drop-shadow(0px 2px 4px #D1D3D6)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            border: "1px solid #eee",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#616771",
            }}
          >
            Detail 2
          </span>
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#FA551E",
            }}
          >
            value
          </span>
        </div>
        <div
          style={{
            /* Group */

            filter: " drop-shadow(0px 2px 4px #D1D3D6)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            border: "1px solid #eee",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#616771",
            }}
          >
            Detail 3
          </span>
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#FA551E",
            }}
          >
            value
          </span>
        </div>
        <div
          style={{
            /* Group */

            filter: " drop-shadow(0px 2px 4px #D1D3D6)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            border: "1px solid #eee",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#616771",
            }}
          >
            Detail 4
          </span>
          <span
            style={{
              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "17px",

              color: "#FA551E",
            }}
          >
            value
          </span>
        </div>
      </div>
      <div style={{ marginTop: "20px",marginBottom:"10vh" }}>
        <h1
          style={{
            margin: "20px 0px",
            fontFamily: "Canaro",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "17px",

            color: "#616771",
          }}
        >
          Product Description
        </h1>
        <span
          style={{
            fontFamily: "Canaro",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "17px",

            color: "#616771",
            
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </span>
      </div>
    </GalpLayout>
  );
}
