export default {
  //BOTTOM NAV
  home: "Home",
  buybye: "BuyBye",
  account: "Account",
  purchaseList: "Purchase List",
  //HOME
  openBuyBye: "Open BuyBye",
  // CABINET
  openBuyByeTitle: "Open the door",
  openBuyByeSubtitle: "Enter the code located at the top corner of the door to start shopping.",
  // receipts
  purchaseLabel: "Purchase",
  receiptSubtitle: (value) => {
    return `Purchase ${value}`;
  },
  receiptDuration: (value) => {
    return `Your Purchase Time Was <strong>${value}s.</strong>`;
  },
  // close door page
  closeDoor: {
    p1:"Processing payment",
    p2:"Please wait."
  },
  //Cases List
  casesListLabel: "Talk to us",
  casesListItensCase: "Case",
  casesListItensMachine: "Machine",
  casesListItensStatus: "Status",
  casesListItensCreatedAt: "Created At",
  casesListNoItens: "You don't have any cases.",
  casesListItensStatusTrad: {
    Open: "Open",
    Closed: "Closed",
    "In Resolution": "In Resolution",
  },
  // help form
  helpForm: {
    title: "Customer Feedback",
    mentionId: "Machine or Invoice ID",
    subject: "Subject",
    description: { label: "Message", placeholder: "Enter your message" },
    contactMethod: {
      lable: "Contact Method",
      phone: "Mobile Phone",
      email: "E-mail",
    },
    email: "E-mail",
    phone: "Mobile Phone",
  },
  // verify
  verify: { title: "Validation Code" },
  //caseManagement
  caseManagement: {
    title: "Talk to us",
    subtitle: {
      title: "",
      subtitle:
        "Need assistance? Let us know and and we’ll do our best to help with your enquiry.",
    },
  },
  // actions
  actions: {
    sendMessage: "Enviar mensagem",
    setNewPassword: "Set New Password",
    getCode: "Get verification code",
  },
  //error
  error: {
    requiredFields: "Fill in all required fields.",
    validData: "Please fill in the fields with reliable information.",
  },
  //forgot Password
  forgot: {
    phoneNumber: "Enter your phone number",
    helpTip: "",
  },
  receipt:{
    download:"Download receipt",
    help:"Have any questions?",
    talk:"Talk to us"
  },
  // global
  global: {
    download:"Download receipt",
    email: "E-mail",
    name: "Name",
    phone: "Phone number",
    username: "Name",
    acceptTerms: "* By clicking your are accepting",
    signInTip: "Already have an account? Login",
    nif: "TIN",
    logout: "Logout",
    pt: " Português",
    en: " English",
    language: "Language",
    password: "Password",
    newPassword: "New password",
    signIn: "Login",
    forgotPassword: "Forgot password?",
    signup:"Sign up",
    signUp: "Don't have an account? Sign up",
    agree:"By signing up you agree to the",
    terms: "Terms and Conditions ",
    policy:"Policy and Privacy"
  },
  //inShop
  inShop: {
    title: "Shop",
    text: {
      p1: "Pick the products you want",
      p2: "Close the door to complete your purchase.",
      p3: "Please keep the products you do not intend to buy in the original place.",
    },
  },
  //payments
  payments: {
    title: "Payment Cards",
    noCards: "Register your 1st Card.",
    expirationDate: "Expiration Date",
    modalTitle: "New card registration",
    epg: {
      body: `To guarantee the veracity of the card we will capture 1 AED on card
      registration. Don't worry, this amount will be returned after the
      operation. 
      <br></br>All card details are protected by 3D secure transaction
      provided by Etisalat&reg;.`,
    },
    stripe: {
      body: `Our payment system may send a request to the issuing bank for a 1€ authorization to verify that the card is valid and the bank will allow it to be authorized.
      <br></br>This is a temporary authorization charge and it will disappear from your statement.`,
    },
  },
  // profile form
  profile: { title: "Profile" },
  // debt form
  debt: {
    title: "Ups. It looks like you have a pending debt.",
    body: (values) => {
      if (!values) {
        values = { total: "", currency: "" };
      }
      return `To continue shopping at Galp Grab & Go you have to pay the missing amount ${values.currency} ${values.total} (refers to ${values.debts} receipts). By clicking continue, you are authorizing the missing payment.`;
    },
    cancel: "Cancel",
    continue: "Continue",
  },
};
