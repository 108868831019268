import React from "react";
import Grid from "@material-ui/core/Grid";
export default function Loader() {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <img  src={process.env.PUBLIC_URL + "/icons/Loader.svg"} />
    </Grid>
  );
}
