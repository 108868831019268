import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import logo from "../FoodSVG.svg";
import AuthService from "../services/auth-service";
import useStyles from "../css/signup";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [password, setPassword] = React.useState("");

  const handleChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    //{username, phone, password, role: user}
    const res = await AuthService.updateUser({
      password,
    });

    if (res.data.success) {
      history.push("/");
    }
  };

  return (
    <AppLayout>
      <BackButton history={history} location={"/signin/"} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} className={classes.avatar} alt="avatar" />

          <form className={classes.form} noValidate onSubmit={handleRegister}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={handleChange("password")}
              name="password"
              label={Languages[locale].global.newPassword}
              type="password"
              id="password"
              
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {Languages[locale].actions.setNewPassword}
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </AppLayout>
  );
}
