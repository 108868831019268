import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import MuiPhoneNumber from "material-ui-phone-number";
import AuthService from "../services/auth-service";

import useStyles from "../css/forgot";
import logo from "../FoodSVG.svg";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn() {
  const classes = useStyles();
  const { locale } = React.useContext(LocaleContext);
  const history = useHistory();
  const [phone, setPhone] = React.useState("");

  const handleOnChange = (value) => {
    setPhone(value);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    //{username, phone, password, role: user}
    const res = await AuthService.getGenericCode({
      phone,
    });
    if (res.data.success) {
      history.push("/verify?forgot=1");
    }
  };

  return (
    <AppLayout>
      <BackButton history={history} location={"/signin/"} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} className={classes.avatar} alt="avatar" />
          <Typography
            style={{ marginTop: "20px", fontWeight: "400", color: "#616771",alignSelf:"flex-start" }}
          >
            {" "}
            {Languages[locale].forgot.phoneNumber}
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleRegister}>
            <MuiPhoneNumber
              style={{ width: "100%" }}
              defaultCountry={"pt"}
              onChange={handleOnChange}
              value={phone}
            />
            <p
              className={classes.tip}
              style={{
                color: "#FA551E",
                justifyContent: "center",
                display: "flex",
                fontWeight: "500",
              }}
            >
              {Languages[locale].forgot.helpTip}
            </p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ fontWeight: "bolder", textTransform:"none" }}
            >
              {Languages[locale].actions.getCode}
            </Button>
          </form>
        </div>
        
      </Container>
    </AppLayout>
  );
}
