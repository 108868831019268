import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";

export default function errorMessage({ ...props }) {
  return (
    <Dialog
      open={
        props.errorMessage.message && props.errorMessage.message != ""
          ? true
          : false
      }
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          borderRadius: "30px",
          minWidth: "285px",
        },
      }}
    >
      <DialogContent>
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {"Error"}
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.errorMessage.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", borderTop: "1px solid #eee" }}
      >
        <Button onClick={props.handleClose} color="primary">
          {"Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
