import React from "react";
import AppLayout from "./layout";
import ReactCodeInput from "react-code-input";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../components/container";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import BuyByeService from "../services/buybye-service";
import clsx from "clsx";
import Loader from "./loader";
//const DNS = process.env.DNS || "app.reckon.ai";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  codeContainer: {
    display: "flex",
    justifyContent: "space-around",
    top: "525px",
    position: "absolute",
    width: "100%",
  },
  header: {
    position: "relative",
    height: "60px",
    //overflow: "hidden",
    margin: "20px 0",
    willChange: "transform, opacity",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    color: "white",
    width: "95%",
  },
  btn: {
    position: "absolute",
    width: "100%",
    top: "76%",
    color: "white",
  },
}));
const Address = ({ data }) => (
  <div>
    <address className="header__address">
      <i className="fas fa-map-marker-alt" />
      {data.subtitle}
    </address>
  </div>
);
const HeaderTitle = ({ data }) => (
  <h1 className="header__title">{data.title}</h1>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
  </div>
);
const HeaderItem = ({ data }) => {
  return (
    <Container className="header__item">
      <HeaderTop data={data} />
      <Address data={data} />
    </Container>
  );
};
const OpenBuyBye = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [value, setValue] = React.useState(undefined);
  const { locale } = React.useContext(LocaleContext);
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState();
  const [debt, setDebt] = React.useState(false);
  const [_error, set_Error] = React.useState(null);
  //const [currentUser, setCurrentUser] = useState(undefined);

  const handleClose = () => {
    setError(false);
    setOpen(false);
  };
  const handleSubmit = async () => {
    let res = await BuyByeService.customerPayDebt();

    if (res.success && res.result.debts.length <= 0) {
      handleClose();
      set_Error(true);
      let msg = { pt: "Pago com sucesso.", en: "Payment Successful." };
      setError(msg[locale]);
    } else {
      if (res.result.debts.length === values.debts) {
        let _error = {
          pt: "Erro ao processar o pagamento. Tente trocar o cartão.",
          en: `Can't process your payment.Try another card.`,
        };

        set_Error(_error[locale]);
      } else {
        let _error = {
          pt: `Erro ao processar o pagamento apenas conseguimos processar ${res.result.payment.debts.length} de ${values.debts} débitos. Tente trocar o cartão.`,
          en: `We can only process ${res.result.payment.debts.length} of ${values.debts} debts .Try another card.`,
        };

        set_Error(_error[locale]);
      }
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const sendOpen = async (value) => {
    if (value) {
      setLoader(true);
      setValue(value);
      let res = await BuyByeService.openBuyByeToShop(value);
      if (res.success) {
        await BuyByeService.setToken("uuid", {
          id: res.result,
        });
        history.push("/in-shop");
      } else if (res.errorMessage.debt) {
        let _customerData = await BuyByeService.getCustomer();
        setValues({
          currency: _customerData.result.currency.symbol,
          total: _customerData.result.debts.total,
          debts: _customerData.result.debts.debts.length,
        });
        let _error = "Seems you have a pending debt.";
        handleOpen();
        setError(_error);
        setDebt(true);
        setLoader(false);
      } else {
        let _error;
        if (res.errorMessage && res.errorMessage.message) {
          if (res.errorMessage.message.toLowerCase() == "error")
            _error = `Something went wrong. Try again later`;
          else _error = res.errorMessage.message;
          if (_error.toLowerCase() == "add a card") {
            setTimeout(() => {
              history.push("/profile?value=1");
            }, 750);
          }
        }
        console.log(_error);
        setError(_error);
        setLoader(false);
      }
    } else {
      setError("Insert a valid number.");
    }
  };
  const onClick = () => {
    sendOpen(value);
  };

  const codeStyle = {
    inputStyle: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      fontSize: "1.5em",
      height: "50px",
      textAlign: "center",
      color: "black",
      border: "1px solid lightgray",
    },
    inputStyleInvalid: {
      fontFamily:
        "avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto,noto, segoe ui, arial, sans-serif",
      margin: "5px",
      MozAppearance: "textfield",
      width: "50px",
      borderRadius: "6px",
      textAlign: "center",
      fontSize: "1.5em",
      height: "50px",
      color: "black",
      border: "1px solid lightgray",
    },
    onChange: async (a) => {
      if (a.length === 4) {
        sendOpen(a);
      }
    },
  };

  if (loader) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  return (
    <AppLayout logo={false} style={{ padding: 0 }}>
      <CabinetCode
        place={Languages[locale].openBuyByeTitle}
        text1={Languages[locale].openBuyByeSubtitle}
        codeStyle={codeStyle}
        classes={classes}
        error={error}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {Languages[locale].debt.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {Languages[locale].debt.body(values)}
            <p style={{ color: "red" }}>{_error}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {Languages[locale].debt.cancel}
          </Button>
          <Button onClick={handleSubmit} type="submit" value="Submit">
            {Languages[locale].debt.continue}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={error && !debt ? true : false}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            borderRadius: "30px",
            minWidth: "285px",
          },
        }}
      >
        <DialogContent>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {"Error"}
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", borderTop: "1px solid #eee" }}
        >
          <Button onClick={handleClose} color="primary">
            {"Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </AppLayout>
  );
};

export default OpenBuyBye;

function CabinetCode(props) {
  const { place, text1, codeStyle, classes } = props;

  return (
    <div className="container-center-horizontal">
      <div className="cabinet-code screen" data-id="5:2">
        <div className="overlap-group5" data-id="an|wHhpeHsC">
          <div className="overlap-group4" data-id="an|x2QiW43u">
            <div className="place buybye-flow-header" data-id="5:5">
              {place}
            </div>
            <p className="text-1 buybye-flow-text" data-id="5:6">
              {text1}
            </p>
            <div className={clsx(classes.codeContainer)}>
              <ReactCodeInput type="number" fields={4} {...codeStyle} />
            </div>
          </div>
          <img className="frame" src={process.env.PUBLIC_URL + "/frame1.svg"} />
        </div>
      </div>
    </div>
  );
}
