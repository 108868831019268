import React, { useEffect } from "react";
import "../styles/profile.css";
import AppLayout from "./layout";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import "../styles/events.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ReactComponent as PersonPinIcon } from '../icons/Profile.svg';
import { ReactComponent as PaymentIcon } from '../icons/Cards.svg';
import { ReactComponent as HelpIcon } from '../icons/CustomerSupport.svg';
import Box from "@material-ui/core/Box";
import ProfileForm from "../components/profileForm";
import Payments from "../components/payments";
import CaseList from "../components/casesList";
import AuthService from "../services/auth-service";
import BackButton from "../components/backButton";
import queryString from "query-string";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "60px",
    height: "60px",
    marginTop: "30px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  h: {
    background: `#ffffff !important`,
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1,
  },
  submit: {
    margin: "10px",
  },
  appWrapp: {
    width: "100%",
    height: "100%",
    margin: "15vh auto 10px",
    cursor:
      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAZZJREFUeNq8VtFxgzAMBU/ACOkGZIKYDZK/XL+aCRImwZ6AfvXyl24QMgHeoBnBI1TinnuqC2kDKbrTcRek9yTZeiRNbth2+5zRQ5OvyPPotSO/kDfH45sfwkhvAB/I9+QZfm6iMI0ng1ty00eU9oBzpSfyBUAtJb4PFLJGEUx2Jd9QrBskoIQXetSoaheAQapFNx6jcYKoxnvOe/1BAJAWs+VKrvQbg1Y985fnUFJsQ7ELdM6xy0Ceipm3qGAJ8Arn8Bfj+ZcgadEh43iFgANmvhsB3uVzDucyBrC6/BTVf3C7FFBgLOdknBUY1xmjelLi8CyCqmS8hVwLTK2wRAnfGBx0PoEgZwxxrVcKgE20PFNMi8XMVfQyewDBNwyV/LPFBP4BmD4mcNHcppo8T6cguZ2eYL3dBHDeJQdtYrsoMHqoIls5gSDk7oMgKmg4L4ZGF0xoRoAbbPEa47FSiwz0vGbBYuG6k0SKXQ0s83WL0MUGd/gkSIpfzsRBf0oh1xnk3s/7wZnlkznLR//evy1D3QX7FGAAhvHiHphAQV8AAAAASUVORK5CYII='), auto",
    padding: "10px 0 0 10px",
    position: "relative",
    left: "-10px",
    top: -"10px",
  },
}));

export default function Profile({ children, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const parsed = queryString.parse(history.location.search);
  const [value, setValue] = React.useState(
    parsed && parsed.value ? parseInt(parsed.value) : 0
  );
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");


  useEffect(() => {
    let user = AuthService.getCurrentUser();

    if (user) {
      if (user.user) user = user.user;
      setName(user.username);
      setEmail(user.email);
      setPhone(user.phone);
      //setImg(user.img);
    } else {
      history.push("/signin");
    }
  }, [history]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push({
      pathname: "/profile",
      search: "?value=" + newValue,
    });
  };

  const logout = () => {
    history.push("/logout");
  };

  const onChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        break;
      default:
        setName(event.target.value);
    }
  };

  let letters = "";
  if (name)
    name.split(" ").forEach((name) => {
      letters = letters + name.charAt(0);
    });

  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <div>
      
      </div>
      <div>
        <div className="">
          <div className="app-container">
            {/* @main page view */}
            <div className="view-main">
              <BackButton
                history={history}
                location={"/"}
               
              />
              <div className={[ "header-wrapp",classes.h].join(" ")} >
                <div className="">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transform:"scale(1.5)"
                    }}
                  >
                    <Link href="/">
                      <img
                        src={process.env.PUBLIC_URL + "/full.svg"}
                        style={{}}
                      />
                    </Link>

                    <span
                      style={{
                        position: "absolute",
                        fontFamily: "Canaro",
                        fontSize: "20px",
                        color: "white",
                        fontWeight: "bolder",
                      }}
                    >
                      {letters.toUpperCase()}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop:"20px"
                    }}
                  >
                    <h1
                      style={{
                        fontFamily: "Canaro",
                        fontSize: "20px",
                        color: "#353C47",
                      }}
                    >
                      {name}
                    </h1>
                  </div>
                </div>
              </div>
              <Box style={{borderBottom:"1px solid #eee"}}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                style={{borderBottom:"2px solid #eee"}}
              >
                <Tab icon={<PersonPinIcon />} {...a11yProps(0)} />
                <Tab icon={<PaymentIcon />} {...a11yProps(1)} />
                <Tab icon={<HelpIcon />} {...a11yProps(2)} />
              </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <ProfileForm
                  name={name}
                  email={email}
                  phone={phone}
                  onChange={onChange}
                  logout={logout}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Payments />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <CaseList />
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
