import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import useStyles from "../css/login";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import Copyright from "../components/copyright";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import AuthService from "../services/auth-service";
import logo from "../FoodSVG.svg";
import ErrorMessage from "../components/errorMessage";
import ChooseLocale from "../components/chooseLocale";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import _config from "../config/config";
export default function SignIn() {
  const classes = useStyles();
  const form = useRef();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(undefined);
  const { locale, setLocale } = React.useContext(LocaleContext);
  const handleChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    switch (prop) {
      case "email":
        setEmail(event.target.value.toLowerCase());
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault();
    const res = await AuthService.login({ email, password });

    if (res.data.success) {
      let _locale = res.data.result.user.locale;
      if (_locale && _config.changeLocale) {
        setLocale(res.data.result.user.locale);
      } else setLocale("en");
      history.push("/");
    } else {
      if (
        res.data.errorMessage.hasOwnProperty("validation") &&
        !res.data.errorMessage.validation
      ) {
        await AuthService.newValidationCode({ email });
        history.push("/verify");
      }
      setError(res.data.errorMessage.message);
    }
  };

  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <BackButton history={history} location={"/home/"} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} className={classes.avatar} alt="avatar" />
          <ErrorMessage errorMessage={{ message: error }} handleClose={()=>{setError('')}}></ErrorMessage>

          <form
            className={classes.form}
            noValidate
            onSubmit={handleRegister}
            ref={form}
          >
            <TextField
              variant="outlined"
              margin="normal"
              
              fullWidth
              value={email}
              onChange={handleChange("email")}
              id="email"
              label={Languages[locale].global.email}
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              
              fullWidth
              value={password}
              onChange={handleChange("password")}
              name="password"
              label={Languages[locale].global.password}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid
              container
              style={{
                justifyContent: "flex-end",
              }}
            >
              <Link href="/forgot/password" variant="body2">
                {Languages[locale].global.forgotPassword}
              </Link>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{
                fontWeight: "bolder",
                fontSize: "1.3em",
                textTransform: "none",
                fontFamily: 'Canaro',
              }}
            >
              {Languages[locale].global.signIn}
            </Button>
            <Grid
              container
              style={{
                alignItems: "center",
                justifyContent: "center",
               
              }}
            >
              <Link
                href="/signup"
                variant="body2"
                style={{ fontWeight: "bolder" }}
              >
                {Languages[locale].global.signUp}
              </Link>
            </Grid>
          </form>
        </div>
        <ChooseLocale
          label={false}
          locale={locale}
          value={locale}
        ></ChooseLocale>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </AppLayout>
  );
}
