import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import BackButton from "../components/backButton";
import logo from "../FoodSVG.svg";
import AuthService from "../services/auth-service";
import useStyles from "../css/signup";
import ErrorMessage from "../components/errorMessage";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = React.useState(undefined);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { locale } = React.useContext(LocaleContext);
  const handleOnChange = (value) => {
    setPhone(value);
  };
  const handleChange = (prop) => (event) => {
    //setValues({ ...values, [prop]: event.target.value });
    console.log(prop)
    switch (prop) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "phone":
          setPhone(event.target.value);
          break;
      default:
        break;
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault({
      username: name,
      phone,
      password,
      email,
      role: "user",
      locale,
    });
    function emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    let _phone;
    if(!phone.includes('+')){
      _phone = `+351${phone}`
    }

    console.log(_phone)


    if (emailIsValid(email)) {
      console.log();
      const res = await AuthService.register({
        username: name,
        phone:_phone,
        password,
        email,
        role: "user",
      });
      if (res.success) {
        history.push("/verify");
      } else {
        setError(res.errorMessage.message);
      }
    } else {
      setError(Languages[locale].error.validData);
    }
  };

  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <BackButton
        history={history}
        location={"/signin/"}
        style={{ color: "#FA551E" }}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} className={classes.avatar} alt="avatar" />
          <ErrorMessage errorMessage={{ message: error }} handleClose={()=>{setError('')}}></ErrorMessage>

          <form className={classes.form} noValidate onSubmit={handleRegister}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={name}
              onChange={handleChange("name")}
              id="name"
              label={Languages[locale].global.username}
              name="text"
              autoComplete="name"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={email}
              onChange={handleChange("email")}
              id="email"
              label={Languages[locale].global.email}
              name="email"
              autoComplete="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              autoComplete="phone"
              fullWidth
              value={phone}
              onChange={handleChange("phone")}
              name="phone"
              label={Languages[locale].global.phone}
              type="number"
              id="phone"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={password}
              onChange={handleChange("password")}
              name="password"
              label={Languages[locale].global.password}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
                color: "#616771",
                fontWeight: "bolder",
              }}
            >
              <span className="canaro-semi-bold-shuttle-gray-12px">
              {Languages[locale].global.agree}
              </span>
              <span className="canaro-semi-bold-mountain-mist-12px">
                &nbsp;
              </span>
              <Link href="/document/terms" style={{ color: "#FA551E" }}>
              {Languages[locale].global.terms}
              </Link>
              <span className="canaro-semi-bold-mountain-mist-12px">
                &nbsp;{" "}
              </span>
              <span className="canaro-semi-bold-shuttle-gray-12px">&amp; &nbsp;</span>
              <Link href="/document/privacy" style={{ color: "#FA551E" }}>
               {Languages[locale].global.policy}
              </Link>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{
                fontWeight: "bolder",
                fontSize: "1.3em",
                textTransform: "none",
              }}
            >
              {Languages[locale].global.signup}
            </Button>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                justifyContent: "center",
              }}
            >
              <Link
                href="/signin"
                variant="body2"
                style={{ fontWeight: "bold", fontSize: "1.1em" }}
              >
                {Languages[locale].global.signInTip}
              </Link>
            </Grid>
          </form>
        </div>
      </Container>
    </AppLayout>
  );
}
