import React from "react";
import AppLayout from "./layout";
import "../styles/process.css";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import { useHistory } from "react-router-dom";

export default function CloseDoor() {
  const classes = undefined;
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [expired, setExpired] = React.useState("");

  const changeToHome = () => {
    history.push("/");
  };
  React.useEffect(() => {
    setTimeout(() => {
      let _expired = {
        pt:
          "Parece que a operação está a demorar mais que o previsto. Poderá consultar o seu recibo assim que concluída.",
        en:
          "It seems that the operation is taking longer than anticipated. You will be able to consult your receipt as soon as it is completed.",
      };
      setTimeout(() => {
        changeToHome();
      }, 2500);
      setExpired(_expired[locale]);
    }, 40000);
  }, [expired]);

  /*return (
    <AppLayout
      title={""}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="payment-loader">
        <div className="pad">
          <div className="chip"></div>
          <div className="line line1"></div>
          <div className="line line2"></div>
        </div>
        <div
          className="loader-text"
          dangerouslySetInnerHTML={{
            __html: Languages[locale].closeDoor(expired),
          }}
        ></div>
      </div>
    </AppLayout>
  );*/
  return (
    <AppLayout center style={{ padding: 0 }}>
      <CabinetCode
        place={Languages[locale].closeDoor.p1}
        text1={Languages[locale].closeDoor.p2}
        classes={classes}
      />
    </AppLayout>
  );
}

function CabinetCode(props) {
  const { place, text1 } = props;
  return (
    <div className="container-center-horizontal">
      <div className="cabinet-code screen">
        <div className="overlap-group5" style={{ marginTop: "-145px" }}>
          <div
            className="overlap-group4"
            style={{ top: 150, display: "flex", justifyContent: "center" }}
          >
            <div className="place buybye-flow-header" data-id="5:5">
              {place}
            </div>
            <p className="text-1 buybye-flow-text" data-id="5:6">
              {text1}
            </p>

            <div
              className="lds-default"
              style={{ position: "absolute", top: "525px" }}
            >
              <img  src={process.env.PUBLIC_URL + "/icons/Loader.svg"} />
            </div>
          </div>
          <img
            style={{ transform: "scale(1)" }}
            className="frame"
            src={process.env.PUBLIC_URL + "/frame3.png"}
          />
        </div>
      </div>
    </div>
  );
}
