import React, { useEffect } from "react";
import AppLayout from "./layout";
import GalpLayout from "./galpLayout";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "../components/container";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import AuthService from "../services/auth-service";
import BuyByeService from "../services/buybye-service";
import moment from "moment";
import Loader from "./loader";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import { ReactComponent as DownloadIcon } from "../icons/Download.svg";
import header from "../components/header";
const useStyles = makeStyles((theme) => ({
  tripTime: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15vh",
  },
  codeContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(1),
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-around",
  },
  totalCard: {
    display: "flex",
    paddingBottom: "10px",
    justifyContent: "space-between",
    margin: "10px 0px 20px 0px",
    paddingTop: "12px",
    borderTop: "1px solid #eee",
  },
  header: {
    position: "relative",
    height: "60px",
    overflow: "hidden",
    margin: "20px 0",
    willChange: "transform, opacity",
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    color: "white",
    width: "95%",
  },
  btn: {
    position: "absolute",
    width: "100%",
    top: "81%",
    color: "white",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    /* identical to box height */

    color: "#616771",
    marginBottom: "15px",
  },
  cardSubtitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "21px",
    /* or 175% */

    color: "#616771",
  },
}));

const TotalCard = ({ classes, data, id }) => {
  return (
    <div className={classes.totalCard}>
      <div className={clsx(classes.cardSubtitle)} style={{ fontSize: "14px" }}>
        Total
      </div>
      <div>
        <strong
          className={clsx(classes.cardSubtitle)}
          style={{ fontSize: "14px" }}
        >
          {data.currency} {data.totalValue}
        </strong>
      </div>
    </div>
  );
};

const ProductsCard = ({ classes, data, id }) => {
  return (
    <React.Fragment>
      <div className={clsx(classes.flexRow)}>
        <div className={clsx(classes.flexRow)}>
          <img
            style={{ width: "50px", height: "50px", margin: "auto" }}
            src={data.image}
            alt="prd"
          />

          <div style={{ marginLeft: "20px" }}>
            <Typography className={clsx(classes.cardTitle)}>
              {data.name}
            </Typography>
            <Typography className={clsx(classes.cardSubtitle)}>
              {data.amount} items
            </Typography>
            <Typography className={clsx(classes.cardSubtitle)}>
              {parseFloat(data.price).toFixed(2)} €
            </Typography>
          </div>
        </div>
        <strong
          className={clsx(classes.cardTitle)}
          style={{
            whiteSpace: "nowrap",
          }}
        >
          €{" "}
          {Math.round((data.amount * data.price + Number.EPSILON) * 100) / 100}
        </strong>
      </div>
    </React.Fragment>
  );
};

const DownloadButton = ({ text, click }) => {
  console.log(text);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        cursor: "pointer",
      }}
      onClick={click}
    >
      <DownloadIcon style={{ marginRight: "5px", fill: "#FA551E" }} />
      <span
        style={{
          fontFamily: "Canaro",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "14px",
          color: "#FA551E",
        }}
      >
        {text}
      </span>
    </div>
  );
};
const BottomText = ({ locale }) => {};

const SingleReciept = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [cards, setCards] = React.useState(null);
  const { locale } = React.useContext(LocaleContext);
  console.log("abri");

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
    } else {
      history.push("/signin");
    }
    async function main() {
      let res = await BuyByeService.getUserShop(props.match.params.id);
      if (res.success) {
        setCards(res.result);
        console.log(res.result);
      }
    }
    main();
  }, [history, props]);
  const onClose = () => {
    history.push("/receipts");
    return;
  };


  const handleClick = (id) => async(event) =>{
    const res = await BuyByeService.getInvoice(id);
    if(res.success){
      window.location.replace(res.result.Object.DownloadUrl)
      console.log(res.result.Object.DownloadUrl)
    }
    console.log(id)
  }
  if (!cards) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  const onClick = (id) => (e) => {
    history.push(`/case-management?sessUUID=${id.sessionUUID}&_ref=${id._id}`);
    return;
  };

  console.log("hey");

  return (
    <GalpLayout>
      <Container>
        <header.Header container={false}>
          <header.HeaderItem
            data={{
              click: () => {
                onClose();
              },
              title: Languages[locale].receiptSubtitle(
                cards ? cards.sessionUUID : 0
              ),
              subtitle: cards
                ? `${moment(cards.endDate).format("DD MMM YYYY")} ${moment(
                    cards.endDate
                  ).format("HH:mm")}`
                : "",
            }}
          ></header.HeaderItem>
        </header.Header>

        {cards &&
          cards.products.length > 0 &&
          cards.products.map((card) => (
            <ProductsCard key={Math.random()} classes={classes} data={card} />
          ))}
        <TotalCard classes={classes} data={cards ? cards : {}} />

        <div className={classes.tripTime}>
          {cards.invoice ? (
            <DownloadButton
              text={Languages[locale].global.download}
              click={handleClick(cards._id)}
            ></DownloadButton>
          ) : null}

          <Typography
            style={{
              /* Have any questions? Talk to us */

              fontFamily: "Canaro",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#616771",
            }}
          >
            {`${Languages[locale].receipt.help} `}
            <strong onClick={onClick(cards)} style={{ cursor: "pointer" }}>
              {" "}
              {`${Languages[locale].receipt.talk} `}
            </strong>
          </Typography>
        </div>
      </Container>
    </GalpLayout>
  );
};
export default SingleReciept;
